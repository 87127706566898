import React from "react"
import CommentsIcon from "../images/icons/comments-solid.svg"
import GemIcon from "../images/icons/gem-regular.svg"
import KeyIcon from "../images/icons/key-solid.svg"
import LightBulbIcon from '../images/icons/lightbulb-solid.svg'
import SwordsIco from '../images/icons/swords-filled.svg'
import { createUseStyles } from "react-jss"

const useEntryStyles = createUseStyles((theme) => ({
  iconWrapper: {
    height: 70,
    width: 70,
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    border: ["solid", theme.palette.primary.main, 4],
    color: theme.palette.primary.main,
    padding: 12,
    margin: {
      top: 6,
      bottom: 6,
    },
    borderRadius: "50%",
    verticalAlign: "center",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    marginLeft: 12,
    fontSize: 22,
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      fontSize: 20,
    },
  },
}))

const Entry = (props) => {
  const classes = useEntryStyles()
  const Icon = props.icon
  const iconProps = props.iconProps

  return <div className={classes.wrapper}>
    <div className={classes.iconWrapper}><Icon {...iconProps}/></div>
    <div className={classes.text}>{props.children}</div>
  </div>
}

export const Icons = (props) => {

  return <div>
    <Entry icon={CommentsIcon}>ANSPRECH&#8203;PARTNERIN NUMMER{'\u00A0'}1</Entry>
    <Entry icon={KeyIcon}>VERTRAUTE</Entry>
    <Entry icon={GemIcon} iconProps={{ style: { paddingTop: 4 } }}>EXPERTIN AUF MEINEM GEBIET</Entry>
    <Entry icon={LightBulbIcon}>BERATERIN</Entry>
    <Entry icon={SwordsIco}>SPARRINGSPARTNERIN</Entry>
  </div>
}