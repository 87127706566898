import React from "react"
import { createUseStyles } from "react-jss"
import classNames from 'classnames';

const useStyles = createUseStyles(theme => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > *': {
      minWidth: 0,
    }
  },
  textCenter: {
    textAlign: 'right',
  }
}))

export const Center = ({ children, text }) => {
  const classes = useStyles()

  return <div className={classNames(classes.center, {[classes.textCenter]: text})}>
    {children}
  </div>
}