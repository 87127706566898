import React from "react"
import {createUseStyles} from "react-jss"

const useStyles = createUseStyles({
  anchor: {
    display: 'block',
    position: 'relative',
    top: -140,
    visibility: 'hidden',
  }
})

export const Anchor = (props) => {
  const classes = useStyles();

  // eslint-disable-next-line
  return (<a id={props.name} href={`/#${props.name}`} className={classes.anchor}>{props.children}</a>)
}