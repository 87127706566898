import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles((theme) => ({
  gold: {
    color: theme.palette.primary.main,
  }
}));

export const Gold = (props) => {
  const classes = useStyles();
  return (
    <span className={classes.gold} {...props} />
  );
}