import React from 'react'
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(() => ({
  root: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'start',
    flexWrap: 'wrap',
  }
}))

export const Row = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>
    {children}
  </div>
}