import React from "react"
import { createUseStyles } from "react-jss"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import classNames from "classnames"

const staticSpace = 64
const variableSpace = "15%"
const topSpace = "30%"

const useStylesImg = createUseStyles((theme) => ({
  img: {
    height: "70vh",
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      height: "50vh",
    },
  },
  text: {
    background: "linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.3) 85%, rgba(0, 0, 0, 0) 100%)",
    padding: {
      left: staticSpace,
      right: variableSpace,
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "50%",
    height: "100%",
    position: "absolute",

    alignItems: "flex-start",
    textAlign: "left",
    top: 0,
    left: 0,
    "& h1": {
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      maxWidth: 450,
    },
    "& h2": {
      display: "none",
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      maxWidth: 450,
    },
    "& p": {
      color: theme.palette.white,
      maxWidth: 450,
      fontSize: 20,
    },
    [`@media (min-width: ${theme.vars.maxWidth}px)`]: {
      background: "linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0) 100%)",
      left: "calc((100% - 1100px) / 2)",
      padding: {
        left: 24,
      },
    },
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      background: "rgba(0, 0, 0, 0.6)",
      paddingTop: topSpace,
      justifyContent: "flex-start",
      width: "100%",
      '& p': {
        fontSize: '14px',
        lineHeight: '23px',
      },
      "& h1": {
        display: "none",
      },
      "& h2": {
        display: "inherit",
        fontSize: 24,
        lineHeight: "24px",
      },
    },
  },
  right: {
    left: "inherit",
    right: 0,
    alignItems: "flex-end",
    textAlign: "right",
    paddingLeft: variableSpace,
    paddingRight: staticSpace,
    background: "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 15%, rgba(0, 0, 0, 0.75) 100%)",
    [`@media (min-width: ${theme.vars.maxWidth}px)`]: {
      background: "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.75) 100%)",
      right: "calc((100% - 1100px) / 2)",
      padding: {
        right: 24,
      },
    },
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      background: 'rgba(0, 0, 0, 0.5)',
    }
  },
  cover: {
    display: "none",
    [`@media (min-width: ${theme.vars.maxWidth}px)`]: {
      display: "block",
      top: 0,
      left: 0,
      height: "100%",
      position: "absolute",
      width: "calc((100% - 1100px) / 2)",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  },
  coverRight: {
    left: "inherit",
    right: 0,
  },
}))

const CarouselImg = (props) => {
  const classes = useStylesImg()

  return <div>
    <Img
      className={classes.img}
      imgStyle={
        { objectPosition: props.position, transform: `scale(${props.scale})` }
      }
      fluid={props.image.childImageSharp.fluid}
    />
    <div className={classNames(classes.text, { [classes.right]: props.textRight })}>
      <h1>{props.title}</h1>
      <h2>{props.mobileTitle || props.title}</h2>
      <p>{props.text}</p>
    </div>
    <div className={classNames(classes.cover, { [classes.coverRight]: props.textRight })}/>
  </div>
}

CarouselImg.propType = {
  title: PropTypes.string,
  text: PropTypes.string,
  textRight: PropTypes.bool,
}

export const OwnCarousel = () => {
  const data = useStaticQuery(graphql`
      query {
          image1: file(relativePath: { eq: "Wenke_Business_1.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1920, quality: 85) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          image2: file(relativePath: { eq: "Wenke_Business_4.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1920, quality: 85) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          image3: file(relativePath: { eq: "Wenke_Business_5.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1920, quality: 85) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          image4: file(relativePath: { eq: "Wenke_Business_6.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1920, quality: 85) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          image5: file(relativePath: { eq: "Wenke_Business_6_2.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1920, quality: 85) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  return (
    <div>
      <Carousel
        useKeyboardArrows={false}
        showArrows={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        dynamicHeight={true}
        interval={10000}
        transitionTime={1000}
        showStatus={false}
      >
        <CarouselImg
          image={data.image1}
          position={"50% 30%"}
          scale={"1.4"}
          title={"Respekt"}
          text={"Einander auf Augenhöhe begegnen ist für mich das Fundament einer jeden Beziehung."}
          textRight
        />
        <CarouselImg
          image={data.image2}
          position={"50% 25%"}
          scale={"1.4"}
          title={"Zusammenhalt"}
          mobileTitle={<span>Zusammen<br/>halt</span>}
          /* \u200B */
          text={"Ein vertrautes und partnerschaftliches Miteinander, das die Wünsche meiner Kunden stets im Mittelpunkt behält, ist für mich die Essenz erfolgreicher Zusammenarbeit."}
          textRight
        />
        <CarouselImg
          image={data.image5}
          position={"50% 35%"}
          scale={"1.0"}
          title={"Teamgeist"}
          text={"Ihr Team sollte sich durch eine bewusste Aufgabenverteilung und eine effiziente Arbeitsweise auszeichnen, sodass dem Wachstum ihres Unternehmens nichts mehr im Wege steht. Das ist mir ein großes Anliegen!"}
        />
        <CarouselImg
          image={data.image4}
          position={"50% 15%"}
          scale={"1.1"}
          title={"Ästhetik"}
          text={"Ordentlich, gewissenhaft und mit einem Hang zur Perfektion – Projekte müssen nicht nur gelingen, sie müssen glänzen."}
          textRight
        />
        <CarouselImg
          image={data.image3}
          position={"50% 10%"}
          scale={"1.5"}
          title={"Ansehen"}
          text={"Es ist meine Passion, Sie ins beste Licht zu rücken. Die Corporate Identity Ihres Unternehmens habe ich stets im Blick."}
          textRight
        />
      </Carousel>
    </div>
  )
}